export let simpleGameProjects = [
    {
        title: 'Gomoku',
        intro: 'a AI based Gomoku game, both vs. player. and vs. com.',
        tags: ['vue', 'AI', 'canvas'],
        link: '//gomoku.kotoar.com/',
        release: 'Feb. 2022',
        underDevelopment: false,
        details: 'a game',
        order: 1
    },
    {
        title: 'Rubik\'s Bricks',
        intro: 'A game inspired by rubik\'s cube and 2048',
        tags: ['vue', 'canvas'],
        link: '',
        release: 'under development',
        underDevelopment: true,
        details: 'A game inspired by rubik\'s cube and 2048',
        order: 2
    }
]