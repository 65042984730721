export let experiencesData = [
    {
        title: 'Front-End Development Intern',
        type: 'part-time intern',
        company: 'colonist.io',
        location: 'remote',
        duration: 'Feb. 2022 ~ Present',
        details: 'Front-End Development'
    },
    {
        title: 'Software Development Intern',
        type: 'intern',
        company: 'Ericsson',
        location: 'Nanjing, China',
        duration: 'Dec. 2020 ~ May 2021',
        details: `
            [ Software Development ],
            
            [ Control Algorithm of a Robotics System ]
        `
    }
]

export let educationData = [
    {
        institution: 'University of California, San Diego',
        major: 'Electrical and Computer Engineering',
        degree: 'Master in Science',
        duration: 'Sep. 2021 ~ Mar. 2023',
        location: 'California, US',
        details: 'overall GPA: 3.57 / 4'
    },
    {
        institution: 'Nanjing Normal University',
        major: 'Electrical Engineering',
        degree: 'Bachelor in Engineering',
        duration: 'Sep. 2017 ~ Jun. 2021',
        location: 'Nanjing, China',
        details: 'overall GPA: 90.27%'
    },
]