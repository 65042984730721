<template>
    <div class="record_border_frame">
        <div class="record_line" @click="show_detail = !show_detail">
            <span class="record_item record_headline_info">{{record.institution}}</span>
            <span class="record_item record_item_right symbol" > {{show_detail ? '⬤' : '◯' }} </span>
        </div>
        <div class="record_line">
            <span class="education_stack_item" style="align-items: flex-start">
                <span class="record_item">{{record.major}}</span>
                <span class="record_item">{{record.degree}}</span>
            </span>
            <span class="education_stack_item" style="margin-left: auto;align-items: flex-end">
                <span class="record_item minor_info">{{record.duration}}</span>
                <span class="record_item minor_info">{{record.location}}</span>
            </span>
        </div>
        <div class="record_line minor_info record_item record_detail" v-show="show_detail">
            {{record.details}}
        </div>
    </div>
</template>

<script>
export default {
    name: "RecordEducation",
    props: ["record"],
    data: () => {
        return {
            show_detail: false
        }
    }
}
</script>

<style scoped>
.record_border_frame{
    border-radius: 5px;
    border: 1px dashed darkgrey;
    padding: 20px;
}
.record_border_frame:hover{
    box-shadow: 0 0 15px rgba(0, 0, 0, .1);
}
.record_line {
    margin-top: 2px;
    margin-bottom: 2px;
    display: flex;
    flex-wrap: wrap;
}
.record_item{
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
}
.education_stack_item{
    display: flex;
    flex-direction: column;
}
.record_item_right{
    margin-left: auto;
}
.record_headline_info{
    font-size: 16px;
    font-weight: bold;
}
.record_detail{
    white-space: pre-line;
}
.minor_info{
    color: gray;
    font-size: 14px;
}
.symbol{
    cursor: default;
}
</style>