<template>
    <div id="app" class="main_window">
        <ul class="sidebar_container_left" v-show="showSidebar">
            <li style="list-style-type: none;">
                <img src="./img/iconkotoar.png" alt="kotoar" style="margin-bottom: 20px;height: 180px; width: 180px">
            </li>
            <li style="list-style-type: none;font-weight: bold">kotoar</li>
            <li class="sidebar_item" :class="{sidebar_item_selected: showContent===showType.resume}" @click="showContent=showType.resume">Resume</li>
            <li class="sidebar_item" :class="{sidebar_item_selected: showContent===showType.academic}">Academic</li>
            <li class="sidebar_item" :class="{sidebar_item_selected: showContent===showType.profiles}">Profile</li>
            <li class="sidebar_item" :class="{sidebar_item_selected: showContent===showType.projects}" @click="showContent=showType.projects">Projects</li>
            <li class="sidebar_links_container">
                <span><a href="https://github.com/kotoar">
                    <img src="./img/GitHub-Mark-32px.png" alt="github">
                </a></span>
            </li>
        </ul>
        <div class="main_content" :style="{width: contentWidth + 'px'}">
            <transition name="fade">
                <div class="popup_container" v-show="!showSidebar && showPopup">
                    <ul class="popup_list" @click="showPopup=false">
                        <li style="list-style-type: none;font-weight: bold">content</li>
                        <li class="popup_item" @click="showContent=showType.resume">Resume</li>
                        <li class="popup_item">Curriculum Vitae</li>
                        <li class="popup_item">Profile</li>
                        <li class="popup_item" @click="showContent=showType.projects">Projects</li>
                        <li class="sidebar_links_container">
                            <span><a href="https://github.com/kotoar">Github</a></span>
                        </li>
                    </ul>
                </div>
            </transition>
            <div class="header">
                <div class="popup_icon" @click="showPopup = !showPopup" v-show="!showSidebar">
                    =
                </div>
            </div>
            <div id="content_simple_contact" class="content_item_block " v-show="show_content_item[showContent].simpleContact">
                <simple-contact></simple-contact>
            </div>
            <div id="content_education" class="content_list_block" v-show="show_content_item[showContent].education">
                <div class="content_item_title" @click="show_education_details = !show_education_details">
                    Education {{show_education_details ? '' : '>>'}}
                </div>
                <ul v-show="show_education_details" class="record_list_block">
                    <li class="record_container_style" v-for="record in educations" :key="record.date">
                        <record-education class="record_style" :record="record"></record-education>
                    </li>
                </ul>
            </div>
            <div id="content_experience" class="content_list_block" v-show="show_content_item[showContent].experience">
                <div class="content_item_title" @click="show_experience_details = !show_experience_details">
                    Experience {{show_experience_details ? '' : '>>'}}
                </div>
                <ul v-show="show_experience_details" class="record_list_block">
                    <li class="record_container_style" v-for="record in experiences" :key="record.date">
                        <record-experience class="record_style" :record="record"></record-experience>
                    </li>
                </ul>
            </div>
            <div id="projects_list" v-show="show_content_item[showContent].projects">
                <projects-list :game-projects-data="gameProjects"></projects-list>
            </div>
        </div>
    </div>
</template>

<script>
import {educationData, experiencesData} from "@/data/recordsData";
import RecordExperience from "@/components/RecordExperience";
import RecordEducation from "@/components/RecordEducation";
import ProjectsList from "@/components/ProjectsList";
import SimpleContact from "@/components/SimpleContact";
import {simpleGameProjects} from "@/data/projectsData";

export default {
    name: 'App',
    components: {
        SimpleContact,
        ProjectsList,
        RecordEducation,
        RecordExperience
    },
    data: () => {
        return {
            experiences: experiencesData,
            educations: educationData,
            gameProjects: simpleGameProjects,
            showType: {
                resume: 0,
                academic: 1,
                profiles: 2,
                projects: 3
            },
            showContent: 0,
            show_education_details: true,
            show_experience_details: true,
            show_content_item: {
                0: {
                    simpleContact: true,
                    experience: true,
                    education: true,
                    projects: false
                },
                3: {
                    simpleContact: false,
                    experience: false,
                    education: false,
                    projects: true
                }
            },
            showSidebar: true,
            showPopup: false,
            contentWidth: 700,
        }
    },
    mounted() {
        document.title = 'Ding Wang';
        window.addEventListener('resize', this.windowResize);
        this.windowResize();
    },
    methods: {
        windowResize(){
            let width = document.documentElement.clientWidth;
            if(width < 800){
                this.showSidebar = false;
                this.contentWidth = width;
            } else {
                this.showSidebar = true;
                this.showPopup = false;
                if(width > 1000) {
                    this.contentWidth = 700;
                } else {
                    this.contentWidth = width - 300;
                }
            }
        }
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.main_window{
    display: flex;
    flex-direction: row;
}
.main_content{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;
}
.content_item_block{
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.content_list_block{
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-shrink: 1;
    width: calc(100% - 20px);
}
.content_item_title{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    margin-right: auto;
}
.sidebar_container_left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
}
.popup_container{
    background-color: white;
    z-index: 100;
    width: 90%;
    margin: auto;
    position: absolute;
    float: top;
    align-self: center;
}
.popup_list{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px 20px;
    border: 1px solid black;
    border-radius: 5px;
    margin: 50px 20px 20px;
}
.sidebar_item{
    list-style-type: none;
    margin-top: 15px;
    margin-left: 0;
    padding: 10px;
    width: 150px;
    text-align: left;
    border: 1px dashed gray;
    border-radius: 5px;
    cursor: default;
}
.sidebar_item_selected{
    background-color: lightcyan;
}
.sidebar_item:hover{
    background-color: lightgoldenrodyellow;
}
.popup_item{
    list-style-type: none;
    margin-top: 15px;
    padding: 10px;
    text-align: left;
    border: 1px dashed gray;
    border-radius: 5px;
    cursor: default;
}
.popup_item:hover{
    background-color: lightgoldenrodyellow;
}
.header{
    z-index: 500;
    align-self: flex-end;
}
.popup_icon{
    padding: 5px 10px;
    border: 1px solid black;
    background-color: white;
    border-radius: 5px;
    margin-right: 50px;
}
.sidebar_links_container{
    list-style-type: none;
    margin-top: 15px;
    padding: 10px;
    width: 150px;
    text-align: left;
}
.record_list_block{
    margin: 0;
    padding: 0;
}
.record_container_style{
    list-style-type: none;
    margin: 0;
}
.record_style{
    margin-bottom: 30px;
}
</style>
