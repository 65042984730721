<template>
    <div class="project-main-container" :style="borderColorList[color]">
        <div class="project-title project-content-line">
            <a :href="projectData.link" :style="colorList[color]">
                {{projectData.title}}
            </a>
        </div>
        <div class="project-content-line" style="text-align: left"
             :class="{'project-intro-text-preview': !showDetail, 'project-intro-text-detailed': showDetail}"
             @click="showDetail = true">
            {{projectData.intro}}
        </div>
            <ul class="project-tag-container project-content-line" v-show="showDetail">
                <li v-for="tag in projectData.tags" :key="tag" class="project-tag-item" v-show="showDetail">
                    {{ tag }}
                </li>
            </ul>
            <div class="project-content-line"  v-show="showDetail">
                {{projectData.details}}
            </div>
            <div v-show="showDetail">
                <span class="collapse-button" @click="showDetail = false">
                    collapse
                </span>
            </div>
    </div>
</template>

<script>
export default {
    name: "SingleProject",
    props: ['projectData', 'color'],
    data: () => {
        return {
            showDetail: false,
            borderColorList: {
                'purple': {
                    borderColor: 'darkviolet'
                },
                'green': {
                    borderColor: 'green'
                }
            },
            colorList: {
                'purple': {
                    color: 'darkviolet'
                },
                'green': {
                    color: 'green'
                }
            },
        }
    }
}
</script>

<style scoped>
.project-main-container{
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    align-items: flex-start;
    border-radius: 5px;
    border: 1px dashed;
    width: calc(100% - 60px);
}
.project-title{
    font-weight: bold;
    font-size: 18px;
    margin: 5px;
}
.project-content-line{
    margin-bottom: 10px;
}
.project-intro-text-preview{
    color: gray;
    font-size: 14px;
    cursor: default;
}
.project-intro-text-detailed{
    color: black;
    font-size: 14px;
}
.project-tag-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.project-tag-item{
    padding: 2px 5px;
    border-radius: 5px;
    background-color: lightcyan;
    list-style-type: none;
    margin-right: 5px;
}
.collapse-button{
    color: gray;
    cursor: default;
    align-self: flex-end;
}
</style>