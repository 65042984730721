<template>
    <div class="simple_contact">
        <div class="contact_headline contact_line">Ding Wang</div>
        <div class="contact_line">
            <span class="contact_info_item">email: me@kotoar.com</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "SimpleContact"
}
</script>

<style scoped>
.simple_contact{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.contact_line{
    margin-bottom: 10px;
}
.contact_headline{
    font-weight: bold;
    font-size: 20px;
}
.contact_info_item{
    margin-right: 10px;
    color: darkgray;
}
</style>