<template>
    <div class="project-top-container">
        <div class="headline">Projects</div>
        <div class="sub-headline">Games</div>
        <ul class="project-group-container">
            <li v-for="gameProject in gameProjectsData" :key="gameProject.order" class="single-project-arrangement">
                <single-project :project-data="gameProject" color="purple"></single-project>
            </li>
        </ul>
    </div>
</template>

<script>
import SingleProject from "@/components/SingleProject";

export default {
    name: "projectsList",
    components: {SingleProject},
    props: ['gameProjectsData']
}
</script>

<style scoped>
.project-top-container{
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.project-group-container{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0;
    padding: 0;
}
.headline{
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 20px;
    margin-left: 10px;
    align-self: self-start;
}
.sub-headline{
    font-weight: bold;
    font-size: 20px;
    margin-left: 10px;
    align-self: self-start;
}
.single-project-arrangement{
    list-style-type: none;
    margin: 10px;
    padding: 0;
}
</style>