<template>
    <div class="record_border_frame">
        <div class="record_line" @click="show_detail = !show_detail">
            <span class="record_item record_headline_info">{{record.company}}</span>
            <span class="record_item record_type_pink_info">{{record.type}}</span>
            <span class="record_item record_item_right symbol" > {{show_detail ? '⬤' : '◯' }} </span>
        </div>
        <div class="record_line">
            <span class="record_item">{{record.title}}</span>
            <span class="record_item minor_info">{{record.location}}</span>
            <span class="record_item minor_info">{{record.duration}}</span>
        </div>
        <div class="record_line minor_info record_item record_detail" v-show="show_detail" >
            {{record.details}}
        </div>
    </div>
</template>

<script>
export default {
    name: "RecordExperience",
    props: ["record"],
    data: () => {
        return {
            show_detail: false,
        }
    }
}
</script>

<style scoped>
.record_border_frame{
    border-radius: 5px;
    border: 1px dashed darkgrey;
    padding: 20px;
}
.record_border_frame:hover{
    box-shadow: 0 0 15px rgba(0, 0, 0, .1);
}
.record_line {
    margin-top: 2px;
    margin-bottom: 2px;
    display: flex;
    flex-wrap: wrap;
}
.record_item{
    padding-left: 15px;
    padding-right: 15px;
}
.record_item_right{
    margin-left: auto;
}
.record_headline_info{
    font-size: 16px;
    font-weight: bold;
}
.record_type_pink_info{
    border-radius: 3px;
    padding: 2px 4px;
    background-color: lightpink;
}
.record_detail{
    text-align: left;
    white-space: pre-line;
}
.minor_info{
    color: gray;
    font-size: 14px;
}
.symbol{
    cursor: default;
}
</style>